import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import CacheVideoPlayer from '@/component/video/cacheVideoPlayer'
import './jingqiExplanation.scss'

const JingqiExplanation = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const videoUrl = searchParams.get('videoUrl')

  const handleBack = () => {
    navigate(-1)
  }

  return (
    <div className="jingqi-explanation-container">
      <div className="back-button" onClick={handleBack}>
        ←
      </div>
      {videoUrl && (
        <div className="video-container">
          <div className="video-wrapper">
            <CacheVideoPlayer videoUrl={videoUrl} />
          </div>
        </div>
      )}
    </div>
  )
}

export default JingqiExplanation
