// phonetic.jsx
import React, { useState, useEffect } from 'react'
import SymbolTable from './component/table'
import WordList from './component/word'
import CacheVideoPlayer from '@/component/video/cacheVideoPlayer'
import { get, post } from '@/api/http'
import { useNavigate, useSearchParams, Link } from 'react-router-dom'
import './phonetic.scss'

const Phonetic = () => {
  const [table, setTable] = useState([])
  const [selectItem, setSelectItem] = useState({})
  const [params] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    const init = async (pId, pCId) => {
      const result = await Promise.all([
        post('liao/v1/phonetic/class/get'),
        post('liao/v1/phonetic/list/get'),
      ])
      const phoneticClass = result[0].phonetic_class
      const phoneticList = result[1].phonetic_list
      buildRow7(phoneticClass, pCId)
      buildRow6(phoneticClass, pCId)
      buildRow5(phoneticClass, pCId)
      if (pId) {
        const selectItem = phoneticList.find((item) => item.p_id === pId)
        changeItem(selectItem ?? phoneticList[0])
      } else if (pCId) {
        const selectItem = phoneticClass.find((item) => item.p_c_id === pCId)
        changeItem(selectItem ?? phoneticClass[0])
      } else {
        changeItem(phoneticList[0])
      }
      for (let i = 0; i < 5; i++) buildRow(i, phoneticList, pId)
    }

    let pId = params.get('p_id')
    let pCId = params.get('p_c_id')
    if (!pId && !pCId) pId = 1
    init(Number(pId), Number(pCId))
  }, [])

  const changeItem = async (item) => {
    if (item.symbol) {
      const info = await get('liao/v1/phonetic/get', { p_id: item.p_id })
      setSelectItem(info)
    } else {
      setSelectItem(item)
    }
  }

  const buildRow7 = (phoneticClass, id) => {
    const row = 7
    setTable((prevTable) => [
      ...prevTable,
      {
        name: phoneticClass[0].title,
        row,
        col: 0,
        num: 5,
        active: id === phoneticClass[0].p_c_id,
        item: phoneticClass[0],
      },
      {
        name: phoneticClass[1].title,
        row,
        col: 5,
        num: 9,
        active: id === phoneticClass[1].p_c_id,
        item: phoneticClass[1],
      },
    ])
  }

  const buildRow6 = (phoneticClass, id) => {
    const row = 6
    setTable((prevTable) => [
      ...prevTable,
      {
        name: phoneticClass[2].title,
        row,
        col: 0,
        num: 3,
        item: phoneticClass[2],
        active: id === phoneticClass[2].p_c_id,
      },
      {
        name: phoneticClass[3].title,
        row,
        col: 3,
        num: 2,
        item: phoneticClass[3],
        active: id === phoneticClass[3].p_c_id,
      },
      {
        name: phoneticClass[4].title,
        row,
        col: 5,
        num: 3,
        active: id === phoneticClass[4].p_c_id,
        item: phoneticClass[4],
      },
      {
        name: phoneticClass[5].title,
        row,
        col: 8,
        num: 6,
        active: id === phoneticClass[5].p_c_id,
        item: phoneticClass[5],
      },
    ])
  }

  const buildRow5 = (phoneticClass, id) => {
    const row = 5
    const newRows = []
    for (let i = 0; i < 14; i++) {
      newRows.push({
        name: phoneticClass[6 + i].title,
        row,
        col: i,
        num: 1,
        active: id === phoneticClass[6 + i].p_c_id,
        item: phoneticClass[6 + i],
      })
    }
    setTable((prevTable) => [...prevTable, ...newRows])
  }

  const buildRow = (row, phoneticList, id) => {
    const newRows = []
    for (let i = 0; i < 14; i++) {
      newRows.push({
        name: phoneticList[i + row * 14].symbol,
        row: 4 - row,
        col: i,
        num: 1,
        active: id === phoneticList[i + row * 14].p_id,
        item: phoneticList[i + row * 14],
      })
    }
    setTable((prevTable) => [...prevTable, ...newRows])
  }

  const handleBack = () => {
    navigate(-1)
  }

  return (
    <div className="phonetic-container">
      <div className="back-button" onClick={handleBack}>
        ←
      </div>
      <div className="phonetic-info">
        {selectItem.video ? (
          <div className="info-container">
            {/* 视频内容 */}
            {selectItem.video[0].url && (
              <CacheVideoPlayer
                key={selectItem.video[0].url}
                videoUrl={selectItem.video[0].url}
              />
            )}
            <Link
              className="video_jq"
              to={`/jingqi-explanation?videoUrl=${encodeURIComponent(selectItem.video[1].url)}`}
            >
              {`鲸奇讲解 >`}
            </Link>
            {/* 滑动文字列表 */}
            <WordList words={selectItem.demo_words} />
            <div className="horizontal-line"></div>
            <p className="notice">
              <span style={{ color: '#02bfc4' }}>注意：</span>
              {selectItem.notice}
            </p>
            <p className="desc">{selectItem.desc}</p>
          </div>
        ) : selectItem.disc ? (
          <div className="info-container">
            <p
              className="disc"
              dangerouslySetInnerHTML={{ __html: selectItem.disc }}
            ></p>
          </div>
        ) : null}
      </div>
      <SymbolTable className="table" cells={table} onClickItem={changeItem} />
    </div>
  )
}

export default Phonetic
